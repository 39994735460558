* {
    font-family: $font-fam;
}
.whole_wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100vh;
    .category {
        .category-cover {
            width: 100%;
            height: 151px;
            position: absolute;
            top: 56px;
            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                position: relative !important;
            }
        }
        .top-content {
            display: flex;
            align-items: center;
            justify-content: space-between;
            background: #ffffff;
            box-shadow: 0px 2px 11px rgba(0, 0, 0, 0.1);
            padding: 16px;
            .table-num {
                font-weight: 700;
                font-size: 14px;
                line-height: 18px;
                text-align: center;
                color: $primaryColor;
                text-transform: capitalize;
            }
            .table-btn {
                font-weight: 600;
                font-size: 12px;
                line-height: 15px;
                text-align: center;
                color: $primaryColor;
                background: #f1f1f1;
                border-radius: 87px;
                padding: 6px 12px;
            }
        }
        .delivery_content {
            width: 100%;
            height: 151px;
            display: flex;
            justify-content: center;
            align-items: center;
            .delivery_category {
                background-color: #ffffff;
                padding: 8px 12px;
                border-radius: 75px;
                display: flex;
                justify-content: center;
                align-items: center;
                z-index: 1;
                font-weight: 500;
                font-size: 14px;
                line-height: 17px;
                text-align: center;
                color: $primaryColor;
                .arrowdown {
                    margin-top: 3px;
                    margin-left: 8px;
                }
            }
        }
        .category_container {
            padding: 16px;
            position: relative;
            display: flex;
            flex-direction: column;
            gap: 16px;
            @media only screen and (min-width: 768px) {
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
            }
        }
        .food-items {
            position: relative;
            @media only screen and (min-width: 768px) {
                width: calc(50% - 8px);
            }
            @media only screen and (min-width: 1360px) {
                width: calc(33.33% - 11px);
            }
            .food-itemsimg {
                height: 36.5vw;
                position: relative;
                transition: all 0.1s ease-in-out;
                width: 100%;
                @media only screen and (min-width: 768px) {
                    height: 18.5vw;
                    max-height: 140px;
                }
                img {
                    border-radius: 8px;
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    opacity: 1.6;
                }
            }
            .order_status {
                align-items: center;
                display: flex;
                font-size: 14px;
                font-weight: 500;
                justify-content: center;
                line-height: 140%;
                position: absolute;
                top: 0;
                width: 100%;
                height: 100%;
                background-color: rgba(0, 0, 0, 0.5);
                border-radius: 8px;
                div {
                    color: #fff;
                    opacity: 1;
                    z-index: 1;
                }
            }
            .item_name {
                font-size: 18px;
                line-height: 140%;
                text-align: center;
                color: #fff;
                position: absolute;
                bottom: 6px;
                left: 16px;
                margin: 0px;
                z-index: 1;
                display: flex;
                gap: 4px;
                .availiable_time {
                    font-size: 14px;
                }
            }
            .item_nameinactive {
                font-weight: 700;
                font-size: 24px;
                line-height: 29px;
            }
        }
        .food-itemsimg:after {
            content: "";
            position: absolute;
            z-index: 1;
            border-radius: 6px;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            display: inline-block;
            background: linear-gradient(
                180deg,
                rgba(43, 43, 43, 0.3) 0%,
                rgba(0, 0, 0, 0.6) 88.59%
            );
        }
    }
}
.directory_categorywrapper {
    .category-cover {
        display: none;
        box-shadow: 0px 2px 11px rgba(0, 0, 0, 0.1) !important;
    }
    .delivery_content {
        display: none !important;
    }
    .category_container {
        padding: 8px 16px !important;
        gap: 16px !important;
        flex-direction: column !important;
        flex-wrap: nowrap !important;
        .food-items {
            width: 100%;
            border-radius: 8px;

            &:last-child {
                .directoryitem-title {
                    border-bottom: none;
                }
            }

            .directory-item {
                border-left: none;
                border-right: none;
                border-top: none;
            }
            .directoryitem-title {
                background: none;
                color: #212121;
                font-size: 18px;
                font-weight: bold;
                line-height: 140%;
                text-transform: capitalize;
                display: flex;
                justify-content: space-between;
                align-items: center;
                transition: background-color 0.3s ease;
                padding: 16px 0;
                border-radius: 8px;
                .arrow-icon {
                    transition: transform 0.3s ease-in-out;
                    cursor: pointer;
                }
                .arrow-icon.up {
                    transform: rotate(180deg);
                }
            }
            .accordion-content {
                border-left: none;
                border-right: none;
                background: none;
                max-height: 0;
                overflow: scroll;
                transition: max-height 0.3s ease-in-out;
                -ms-overflow-style: none;
                scrollbar-width: none;
                display: flex;
                flex-direction: column;
                &::-webkit-scrollbar {
                    display: none;
                }

                .items_link {
                    margin: 0 16px;
                    padding: 0;
                    font-size: 16px;
                    font-weight: 500;
                    line-height: 140%;
                    color: #212121;
                    text-transform: capitalize;
                    display: flex;
                    flex-direction: column;
                    &:hover {
                        opacity: 0.8;
                    }
                    &:first-child {
                        border-top: none;
                    }
                    &:last-child {
                        border-bottom: none;
                    }
                }
                &:first-child {
                    border-top: none;
                }
            }

            .accordion-content.open {
                max-height: 1000px;
                margin-top: 16px;
            }
        }
    }
}
.ant-popover {
    width: 300px !important;
    left: 10px !important;
    .ant-popover-title {
        padding: 10px;
        text-align: center;
    }
}

.ant-popover-inner-content {
    display: flex;
    justify-content: space-between;
}

.venue-operating-hour {
    padding: 16px;

    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    color: $primaryColor;
    cursor: pointer;
    background-color: $primaryColor;
    color: #ffffff;
    width: 140px;
    margin: 16px 16px 0px 16px;
    border-radius: 8px;
}

.bookinghours-wrapper {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 16px;
    .bookinghours {
        width: 48%;
    }

    .bookinghours_title {
        font-weight: 500;
        font-size: 16px;
        line-height: 28px;
        color: $primaryColor;
    }
    .time {
        font-weight: 500;
        font-size: 14px;
        line-height: 16px;
        color: $primaryColor;
    }
}

.bookinghours-wrapper .bookinghours:nth-child(2) {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;
    border-left: 1px solid #e9e9e9;
}

//menu & directory css
.menuitems_wrap {
    display: flex;
    flex-wrap: wrap;
    @media only screen and (min-width: 768px) {
        gap: 16px;
    }
    .menu_itemslink {
        .menu_items {
            border-top: 1px solid #e3e3e3;
        }
        &:last-child {
            .menu_items {
                border-bottom: 1px solid #e3e3e3;
            }
        }
    }
    a {
        color: #1d1d1d;
        width: 100%;
        @media only screen and (min-width: 768px) {
            width: calc(50% - 8px);
        }
        @media only screen and (min-width: 1360px) {
            width: calc(33.33% - 12px);
        }

        .menu_items {
            width: 100%;

            .subcat_status {
                display: none;
                @media only screen and (min-width: 768px) {
                    display: flex;
                    justify-content: space-between;
                }
            }
            .menu-wholecontent {
                display: flex;
                justify-content: space-between;
                height: 100%;
                max-width: 100%;
                .menu__GVD {
                    display: none !important;
                    @media only screen and (min-width: 768px) {
                        display: block !important;
                    }
                }
            }
            @media only screen and (min-width: 768px) {
                padding: 16px;
                box-shadow: 0 0 transparent, 0 0 transparent, 0 0 transparent,
                    0 0 transparent, 0 0 0 rgba(29, 29, 29, 0.25),
                    0 0 15px rgba(29, 29, 29, 0.04) !important;
                border: 1px solid #e3e3e3;
                border-radius: 0.25rem;
                height: 238px;
            }
        }
    }
}
.menu_itemsactive {
    display: flex;
    border-radius: 0.25rem;
    border: 1px solid rgba(221, 221, 221, 0.4196078431);
    position: relative;
    background-color: #ffffff;
    transition: all 0.1s ease-in-out;
    box-shadow: 0 0 rgba(0, 0, 0, 0), 0 0 rgba(0, 0, 0, 0), 0 0 rgba(0, 0, 0, 0),
        0 0 rgba(0, 0, 0, 0), 0px 0px 0px rgba(29, 29, 29, 0.25),
        0px 0px 15px rgba(29, 29, 29, 0.04);
    margin-bottom: 12px;
    @media only screen and (min-width: 768px) {
        margin-bottom: 0;
        height: 100%;
    }
    &:hover {
        transform: scale(1.01);
        @media only screen and (min-width: 768px) {
            transform: scale(1) !important;
        }
    }
    @media only screen and (min-width: 768px) {
        max-height: 238px;
    }

    .listingproduct_img {
        aspect-ratio: 1/1;
        border-radius: 4px 0 0 4px;
        position: relative;
        width: 72%;

        img {
            width: 100%;
            height: 100%;
            border-top-left-radius: 0.25rem;
            border-bottom-left-radius: 0.25rem;
            object-fit: cover;
        }
    }
    .listingproduct_none {
        display: none;
    }
}
.menu_inner_content,
.menuinnercontent_opacity {
    padding: 12px;
}

.menu_inner_content,
.menuinnercontent_opacity,
.menu-wholecontent {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: -webkit-fill-available;
    max-width: 60%;

    .menu_subcontentI {
        div {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 4px;
        }

        h4 {
            color: #1d1d1d;
            font-size: 16px;
            font-weight: 700;
            line-height: 140%;
            margin: 0;
            overflow: hidden;
            text-overflow: ellipsis;
            text-transform: capitalize;
            white-space: nowrap;
        }
        p {
            color: #4d4d4d;
            font-family: $font-fam;
            font-size: 14px;
            font-weight: 500;
            line-height: 140%;
            margin-bottom: 4px;
            word-break: break-all;
        }
        .menulist_para {
            display: -webkit-box;

            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;
            @media only screen and (min-width: 860px) {
                -webkit-line-clamp: 2;
            }
        }
        .menulist_popularp {
            display: -webkit-box;

            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
            overflow: hidden;
            @media only screen and (min-width: 860px) {
                -webkit-line-clamp: 2;
            }
        }
        .menulist_para.active {
            display: none;
            @media only screen and (min-width: 768px) {
                display: block;
            }
        }
    }
    .aifire {
        width: 24px;
        height: 24px;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: $blue;
        .aifire-icon {
            font-size: 12px;
            color: #ffffff !important;
        }
    }
    .menu_subcontent {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        .price {
            font-size: 14px;
            line-height: 18px;
            font-weight: 400;
            font-family: $font-fam;
            color: $primaryColor;
        }
        .menu__GVD.active {
            display: none;
            @media only screen and (min-width: 768px) {
                display: flex !important;
                gap: 8px;
            }
        }
        .guestreq_btn {
            background-color: transparent;
            border: none;
            color: #000;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            user-select: none;
            transition: transform 0.3s ease;
        }
    }
}
.menu-wholecontent {
    flex-direction: row;
    @media only screen and (min-width: 768px) {
        flex-direction: column;
    }
}
.menuinnercontent_opacity {
    opacity: 0.6 !important;
}
.menu-wholecontent {
    padding: 16px 0;
    @media only screen and (min-width: 768px) {
        padding: 0;
    }
}
.subcat_status {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 4px;
    .menu_status {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 74px;
        padding: 4px;
        text-transform: uppercase;
        border-radius: 32px;
        color: $secondaryColor;
        font-weight: 400;
        font-size: 12px;
        line-height: 15px;
        background-color: $blue;
    }
}

.cat_name {
    font-weight: 600;
    font-size: 24px;
    line-height: 28px;
    color: $primaryColor;
    text-transform: capitalize;
    margin-bottom: 12px;
}
.catname_none {
    display: none !important;
}
